@import '../../../../../styles/customMediaQueries.css';

:root {
  --navDefaultTextColor: rgb(178, 183, 190);
  --navSelectedTextColor: rgb(226, 144, 6);
}

.priorityLinkWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.priorityLink {
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    transform: scale(1.025);
    border-bottom: 2px solid grey;
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.highlight {
  color: var(--marketplaceColor);
  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.priorityLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
  text-wrap: nowrap;
  color: var(--navDefaultTextColor);
  font-size: 15px;
  font-weight: bold;
  &:hover {
    color: white;
  }
}

.priorityLinkSelectedLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
  text-wrap: nowrap;
  color: var(--navSelectedTextColor);
  font-size: 18px;
  font-weight: bold;
  &:hover {
    color: white;
  }
}
